import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';

export class Elveste extends Component {
  static displayName = Elveste.name;

  render() {
    return (
      <div>
        <ProductOverview Id={8} />
        <ProductOverview Id={9} />
        <ProductOverview Id={10} />
      </div>
    );
  }
}
