import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import { Grid, Image } from 'semantic-ui-react';

export class TilbehoerKaarde extends Component {
  static displayName = TilbehoerKaarde.name;

  render() {
    return (
      <div>
        <ProductOverview Id={22} />
        <ProductOverview Id={24} />
        <ProductOverview Id={28} />
        <Grid>
          <Grid.Row centered>
              <Image src='./kaarde-tilbehoer/accessories.png' alt="GrTab_Anzug" />
              <Image src='./kaarde-tilbehoer/parts.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div >
    );
  }
}
