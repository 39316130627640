import React, { Component } from 'react';
import  { ProductOverview } from '../common/ProductOverview'

export class Masker extends Component {
  static displayName = Masker.name;

  render() {
    return (
      <div>
        <ProductOverview Id={14} />
        <ProductOverview Id={15} />
        <ProductOverview Id={16} />
        <ProductOverview Id={17} />
        <ProductOverview Id={18} />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src='./masker/maskestorrelser.png' alt="GrTab_Anzug" />
        </div>
      </div>
    );
  }
}
