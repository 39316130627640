import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import { Grid, Image } from 'semantic-ui-react';

export class Meldeapparater extends Component {
  static displayName = Meldeapparater.name;

  render() {
    return (
      <div>
        <ProductOverview Id={42} />
        <Grid>
          <Grid.Row centered>
            <Image src='./meldeapparater/kontrol.png' alt="GrTab_Anzug" />
            <Image src='./meldeapparater/stand.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
