import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import { Grid, Image } from 'semantic-ui-react';

export class TilbehoerFleuret extends Component {
  static displayName = TilbehoerFleuret.name;

  render() {
    return (
      <div>
        <ProductOverview Id={20} />
        <ProductOverview Id={21} />
        <ProductOverview Id={22} />
        <ProductOverview Id={25} />
        <Grid>
          <Grid.Row centered>
              <Image src='./fleuret-tilbehoer/accessories.png' alt="GrTab_Anzug" />
              <Image src='./fleuret-tilbehoer/cables.png' alt="GrTab_Anzug" />
          </Grid.Row>
          <Grid.Row centered>
            <Image src='./fleuret-tilbehoer/grips.png' alt="GrTab_Anzug" />
            <Image src='./fleuret-tilbehoer/guards.png' alt="GrTab_Anzug" />
          </Grid.Row>
          <Grid.Row centered>
            <Image src='./fleuret-tilbehoer/parts.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
