import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import data from '../data/data.json'

export class Handsker extends Component {
  static displayName = Handsker.name;

  render() {
    let handsker = data.handsker.handsker;
    return (
      <div>
        <ProductOverview Id={11} />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src='./handsker/handkserstorrelser.png' alt="GrTab_Anzug" />
        </div>
      </div>
    );
  }
}
