import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';

export class Fleuret extends Component {
  static displayName = Fleuret.name;

  render() {
    return (
      <div>
        <ProductOverview Id={19} />
      </div>
    );
  }
}
