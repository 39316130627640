import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';

export class Kaarde extends Component {
  static displayName = Kaarde.name;

  render() {
    return (
      <div>
        <ProductOverview Id={26} />
        <ProductOverview Id={27} />
      </div>
    );
  }
}
